export default function TopLeft({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="120"
      viewBox="0 0 81 121"
      className={className}
    >
      <g transform="translate(-1839.5)">
        <path
          d="M3949.725,3622.11v40l40,40h40v-40l-40-40Z"
          transform="translate(-2109.725 -3582.11)"
          fill="rgb(243, 244, 246)"
          className="stroke-current stroke-1"
        />
        <circle
          cx="20"
          cy="20"
          r="20"
          transform="translate(1880)"
          fill="rgb(243, 244, 246)"
          className="stroke-current stroke-1"
        />
      </g>
    </svg>
  );
}
