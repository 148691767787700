export default function BottomLeft({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="1 1 81 81"
      className={className}
    >
      <g transform="translate(-1759 -998.5)">
        <path
          d="M3845.724,3694.11h-40v40A40,40,0,0,1,3845.724,3694.11Z"
          transform="translate(-2045.724 -2694.11)"
          fill="rgb(243, 244, 246)"
          className="stroke-current stroke-1"
        />
        <path
          d="M3917.724,3734.11v-40h-40A40,40,0,0,1,3917.724,3734.11Z"
          transform="translate(-2077.724 -2694.11)"
          fill="rgb(243, 244, 246)"
          className="stroke-current stroke-1"
        />
        <path
          d="M3805.724,3766.11v40h40A40,40,0,0,1,3805.724,3766.11Z"
          transform="translate(-2045.724 -2726.11)"
          fill="rgb(243, 244, 246)"
          className="stroke-current stroke-1"
        />
        <path
          d="M3877.724,3806.11h40v-40A40,40,0,0,1,3877.724,3806.11Z"
          transform="translate(-2077.724 -2726.11)"
          fill="rgb(243, 244, 246)"
          className="stroke-current stroke-1"
        />
      </g>
    </svg>
  );
}
