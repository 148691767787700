import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import wiki from "wikijs";

// Local
import DefaultLayout from "./Layout";

export default function App() {
  return (
    <Router>
      <Wrapper />
    </Router>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function randomFragment(array) {
  const $array = array[Math.floor(Math.random() * array.length)];
  return $array.fragment;
}

function truncate(str, n, useWordBoundary) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "…"
  );
}

function Wrapper() {
  let query = useQuery();

  const [fetching, setFetching] = useState(true);
  const [prefixes, setPrefixes] = useState([]);
  const [suffixes, setSuffixes] = useState([]);

  const [wikiEntry, setWikiEntry] = useState("");

  const [dictEntry, setDictEntry] = useState([]);

  const [word, setWord] = useState("");

  useEffect(() => {
    if (!fetching && query.get("p") && query.get("s")) {
      let pre = query.get("p");
      let suf = query.get("s");
      if (pre.substring(pre.length - 1, pre.length) === suf.charAt(0)) {
        suf = suf.substring(1);
      }
      setWord(pre + suf);
    }
  }, [fetching, query]);

  useEffect(() => {
    Promise.all([fetch("/data/prefix.json"), fetch("/data/suffix.json")])
      .then((responses) => {
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          })
        );
      })
      .then((data) => {
        setPrefixes(data[0]);
        setSuffixes(data[1]);
        setFetching(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!fetching && word) {
      wiki({
        apiUrl: "https://en.wikipedia.org/w/api.php",
      })
        .find(word)
        .then((page) => {
          async function data() {
            let content = [];
            const summary = await page.summary();
            if (summary.includes("may refer to")) {
              content = await page.content();
            }
            return {
              title: page.title,
              url: page.canonicalurl,
              summary,
              content,
            };
          }
          return data();
        })
        .then((entry) => {
          setWikiEntry(entry);
        })
        .catch((error) => {
          setWikiEntry({ title: "No wikipedia entry found" });
        });
    }
  }, [fetching, word]);

  useEffect(() => {
    if (!fetching && word) {
      try {
        fetch(
          `https://dictionaryapi.com/api/v3/references/collegiate/json/${word}?key=37cc6600-17e4-4f49-85bc-95399053ad6f`
        )
          .then((response) => response.json())
          .then((data) => {
            setDictEntry(data);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [fetching, word]);

  return (
    <DefaultLayout className="px-12 pt-24">
      <div className="border-black md:flex space-y-12 md:space-y-0 md:space-x-24 pb-12">
        <div className="w/full md:w-3/5 xl:w-1/3">
          <a
            href="/"
            className="text-sm mb-8 p-2 rounded-sm border border-black inline-block hover:bg-black hover:text-white cursor-pointer"
          >
            Autolex
          </a>
          {!fetching && (
            <div>
              {word ? (
                <div className="prose">
                  <h2>{word}</h2>
                  {prefixes.length !== 0 && suffixes.length !== 0 && (
                    <Definition
                      prefix={query.get("p")}
                      suffix={query.get("s")}
                      data={{ prefixes, suffixes }}
                    />
                  )}
                </div>
              ) : (
                <div className="prose">
                  <h2>The Autolex</h2>
                  <p>
                    The autolex is a generative tool for conceptual thinking. It
                    currently holds 18,432 possible words.
                  </p>
                </div>
              )}
              <Link
                to={`/?p=${randomFragment(prefixes)}&s=${randomFragment(
                  suffixes
                )}`}
                className="text-black border border-black rounded-sm p-4 mt-12 hover:bg-black hover:text-white flex items-center justify-between space-x-2"
              >
                <span>Find new term</span>
                <svg className="w-3.5 h-3" viewBox="0 0 8.76 7.63">
                  <path
                    className="fill-current"
                    d="M4.29,7,7,4.28H0V3.35H7L4.29.66,5,0,8.76,3.81,5,7.63Z"
                  />
                </svg>
              </Link>
            </div>
          )}
        </div>
        {word && (
          <div className="w-full md:w-2/5 xl:w-1/3">
            <p className="text-sm mb-8 p-2 rounded-sm border border-gray-400 text-gray-400 inline-block cursor-pointer">
              Resources
            </p>
            <div className="w-full text-gray-400 pb-8 border border-gray-400 p-4 hover:text-gray-600 hover:border-gray-600 transition-colors mb-4">
              <div className="prose-sm mb-4">
                {dictEntry.some((entry) => entry.meta) ? (
                  <>
                    <h2>Definitions</h2>
                    {dictEntry.map((entry, index) => (
                      <div key={index}>
                        <b>{entry?.fl}</b>
                        <ul className="list-decimal ml-0">
                          {entry.shortdef.map((def, index) => (
                            <li className="ml-4" key={index}>
                              {def}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <h2>Similar Words:</h2>
                    {dictEntry.map((entry, index) => (
                      <a
                        key={index}
                        href={`https://www.merriam-webster.com/dictionary/${entry}`}
                        rel="noreferrer"
                        target="_blank"
                        className="border border-gray-400 px-2 py-1 rounded-sm m-1 inline-block hover:bg-gray-600 hover:text-gray-50 transition-colors"
                      >
                        <span>{entry}</span>
                      </a>
                    ))}
                  </>
                )}
              </div>
              <a
                href="https://www.merriam-webster.com/"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className="w-10 h-10 text-right transition-opacity opacity-30 hover:opacity-80 filter grayscale"
                  alt="Wikipedia Logo"
                  src="mw.svg"
                />
              </a>
            </div>
            <div className="w-full text-gray-400 pb-8 border border-gray-400 p-4 hover:text-gray-600 hover:border-gray-600 transition-colors">
              <div className="prose-sm mb-4">
                <h2>{wikiEntry.title}</h2>
                {wikiEntry.summary && (
                  <p>{truncate(wikiEntry.summary, 400, true)}</p>
                )}
                {wikiEntry.content?.length > 0 &&
                  wikiEntry.content.map((content, index) => {
                    return (
                      <div key={index}>
                        <h4>{content.title}</h4>
                        <p>{truncate(content.content, 180, true)}</p>
                      </div>
                    );
                  })}
              </div>
              <Link
                to={{ pathname: wikiEntry.url || "https://wikipedia.org" }}
                target="_blank"
              >
                <img
                  className="w-32 text-right transition-opacity opacity-30 hover:opacity-80"
                  alt="Wikipedia Logo"
                  src="wikipedia.svg"
                />
              </Link>
            </div>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
}

function getDescription(array, fix) {
  return array.find((item) => item.fragment === fix)?.description;
}

function Definition({ prefix, suffix, data }) {
  return (
    <p>
      {getDescription(data.suffixes, suffix)}{" "}
      {getDescription(data.prefixes, prefix)}.
    </p>
  );
}
