import { BottomLeft, BottomRight, TopLeft, TopRight } from "./components/svg";

export default function DefaultLayout({ className, children }) {
  return (
    <div className={`${className} min-h-screen`}>
      <a
        href="https://abstract.supply"
        className="hover:text-green-400 fixed top-0 left-0"
      >
        <TopLeft />
      </a>
      <a
        href="https://abstract.supply"
        className="hover:text-blue-400 fixed top-0 right-0"
      >
        <TopRight />
      </a>
      <a
        href="https://abstract.supply"
        className="hover:text-red-400 fixed bottom-0 left-0"
      >
        <BottomLeft />
      </a>
      <a
        href="https://abstract.supply"
        className="hover:text-purple-400 fixed bottom-0 right-0"
      >
        <BottomRight />
      </a>
      {children}
    </div>
  );
}
