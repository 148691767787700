export default function BottomRight({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="40"
      viewBox="0 0 81 41"
      className={className}
    >
      <g transform="translate(-1839.5 -1039.5)">
        <path
          d="M4061.725,3806.11v-40l-40,40Z"
          transform="translate(-2141.725 -2726.11)"
          fill="rgb(243, 244, 246)"
          className="stroke-current stroke-1"
        />
        <circle
          cx="20"
          cy="20"
          r="20"
          transform="translate(1840 1040)"
          fill="rgb(243, 244, 246)"
          className="stroke-current stroke-1"
        />
      </g>
    </svg>
  );
}
