export default function TopLeft({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="80"
      viewBox="0 0 120 80"
      className={className}
    >
      <path
        d="M3885.724,3590.11l40-40h-120v80l40-40Z"
        transform="translate(-3805.724 -3550.11)"
        fill="rgb(243, 244, 246)"
        className="stroke-current stroke-1"
      />
    </svg>
  );
}
